import React from 'react';
import axios from 'axios';

import './App.css';

declare var ZoomMtg

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.1.1/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App() {

  var currentlocation = window.location.href;

  // const ENDPOINT = `https://ap.zinedu.com/`
  // const RETURN_URL =`https://student.myclassroom.digital/`
  const ENDPOINT = `https://apstaging.zinedu.com/`
  const RETURN_URL =`https://akstagng.myclassroom.digital/`
  // const ENDPOINT = `https://apqa.zinedu.com/`
  // const RETURN_URL =`https://qa.myclassroom.digital/`
  // const ENDPOINT = `https://apdev.myclassroom.digital/`
  // const RETURN_URL =`https://dev.myclassroom.digital/`



try{
  var b = currentlocation.split('=');
  // console.log(b);
  var id = b[b.length -1];
  // console.log(id);
}
catch{
  console.log("Invalid URL");
}
  async function getdata(){
    var requestOptions1 = {
     redirect: 'follow',
     headers:{
         'Accept': 'application/json',
         'Content-Type': 'application/json',
     },
    };
    const resp = await axios.get(ENDPOINT+'student/get-live-class-join-status/?status_id='+id,requestOptions1)
  return(resp.data);
  }
  async function main() {
    let abc = await getdata();
    // console.log(abc);
    passWord = abc.live_class_assoc.zoom_password
    let meetinglink  = abc.live_class_assoc.live_class_link;
    // console.log(meetinglink);
    try{
      let link = meetinglink.split('?')
      meetingNumber = link[0].split('/')[4];
        // console.log(meetingNumber);
    }
    catch{
      alert('meeting number not correct');
    }
    if(abc.is_disabled=="Yes"){
      // alert('Already in this meeting with this user');
      // window.location.href = 'https://main.zinedu.com/';
    }

    
    userName = abc.student_assoc.name;
    userEmail = abc.student_assoc.email;
    var mobilenumber = abc.student_assoc.mobile_number;
    var liveclassid = abc.live_class_assoc.id;
    var formdata = new FormData();
    formdata.append('is_disabled',"Yes");
    var requestOptions = {
       method: 'PUT',
       body: formdata,
       redirect: 'follow',
       headers:{
           // 'Authorization': 'Token '+ this.state.token
       },
     };
    fetch(ENDPOINT+'student/update-live-class-join-status/'+id+'/',requestOptions)
    .then(response => response.json())
    .then(json => {
      // console.log(json.Success);
       })
      .catch(error => {
        console.log(error);
    });
  }
  main();

  // heklp

  // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  var signatureEndpoint = 'https://zs.zinedu.com'
  var apiKey = 's_XzbkAkTIWfl2lYa4_DiA'
  var meetingNumber
  var role = 0
  var leaveUrl = RETURN_URL
  var userName 
  var userEmail = 'chintan@zineduclasses.com'
  var passWord 
  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/build/meetings/join#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/build/webinars/join#join-registered-webinar
  var registrantToken = ''

  function getSignature(e) {
    e.preventDefault();

    fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
    .then(response => {
      startMeeting(response.signature)
    }).catch(error => {
      console.error(error)
    })
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      isLockBottom:false,
      meetingInfo: [],
      disableInvite: true,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          apiKey: apiKey,
          userEmail: userEmail,
          passWord: passWord,
          tk: registrantToken,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="App">
      <main>
        <h1>Join Meeting</h1>

        <button onClick={getSignature}>Join Meeting</button>
      </main>
    </div>
  );
}

export default App;
